

.list-group li{
    list-style-type: none;
    line-height: 2.3;
}

.list-group.contact-address{
    font-weight: 500;
     
}

.icon{
    img{
        width: 20px;
        height: 20px;
    }
}