/* Bar setups */
.skills-bar {
  width: 600px;
  border-radius: 10px;
}

.skills-bar .bar:not(first-child) {
  margin: 20px 0;
}

.skills-bar .bar .info {
  margin-bottom: 15px;
}

.skills-bar h1 {
  text-align: center;
  text-transform: uppercase;
  text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.4), 0px 4px 13px rgba(0, 0, 0, 0.1);
}

.skills-bar .bar .info span {
  font-weight: 500;
  font-size: 17px;
  opacity: 0;
  animation: showText 0.5s 1s linear forwards;
}

/* Bar itself */
.skills-bar .bar .progress-line {
  height: 10px;
  width: 100%;
  background: #e1e7fc;
  position: relative;
  transform: scaleX(0);
  transform-origin: left;
  border-radius: 10px;
  animation: animate 1s cubic-bezier(1, 0, 0.5, 1) forwards;
}

/* Skill's Bar */
.bar .progress-line span {
  height: 100%;
  position: absolute;
  border-radius: 10px;
  transform: scaleX(0);
  transform-origin: left;
  background: #7367f0;
  animation: animate 1s 1s cubic-bezier(1, 0, 0.5, 1) forwards;
}

/* Percentage numbers */
.progress-line span ::after,
.progress-line .percentage {
  position: absolute;
  top: -28px;
  right: 0;
  font-weight: 500;
  /* background: #000; */
  color: #000;
  padding: 1px 8px;
  font-size: 12px;
  border-radius: 3px;
  opacity: 0;
  animation: showText2 0.5s 1.5s linear forwards;
}

.progress-line.css span::after {
  content: "90%";
}

.progress-line.javascript span::after {
  content: "70%";
}

.progress-line.mysql span::after {
  content: "65%";
}

.progress-line.php span::after {
  content: "50%";
}

/* Animations */
/* Bar's animation */
@keyframes animate {
  100% {
    transform: scaleX(1);
  }
}

/* Skill's name animation */
@keyframes showText {
  100% {
    opacity: 1;
  }
}

/* Percentage number's animation */
@keyframes showText2 {
  100% {
    opacity: 1;
  }
}
