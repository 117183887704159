a {
  text-decoration: none;
}
.navbar-brand {
  font-size: 22px;
}

.nav-link:hover {
  color: #fff;
}
.navbar-brand:hover {
  color: #fff;
  cursor: pointer;
}

.nav-edit-button:hover {
  cursor: pointer;
}
