

.how-it-works-tabs{
    background: none;
    border: 0;
    color: #7367F0;
    font-size: 0.9rem;
}

.how-it-works-tabs.active{
    background-color: #7367F0;
    color: #fff;
    border-radius: 30px;
    padding: 3px 20px;
}