/* StepIndicator.css */
.step-indicator {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px; /* Adjust spacing as needed */
  }
  
  .step-line {
    flex-grow: 1;
    height: 2px;
    background-color: #ccc; /* Inactive step color */
    margin: 0 5px; /* Adjust spacing as needed */
  }
  
  .step-line.active {
    background-color: blue; /* Active step color */
  }
  