.form-input {
    // margin-bottom: 10px;
    .label {
        display: block;
        font-size: 1rem;
    }

    .input {
        width: 100%;
        border-radius: 5px;
        padding: 8px 6px;
        margin-top: 4px;
        border: 1px solid #D8D6DE;
        padding-left: 8px;
        &::placeholder{
            color: #B8B8B8;
            
        }
    }
}

.btn-outline-primary{
    &:hover{
        background-color: #fff!important;
    }
}