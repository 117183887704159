.auth-card {
    width: 35rem;
    margin: 0 auto;
    background-color: #fff;
    box-shadow: 0px 4px 24px #22292F1A;
    padding: 30px;
    border-radius: 10px;
}

.label {
    font-weight: 300;
    font-size: 0.9rem !important;
}

.input {
    margin-top: 0 !important;
}

.auth-icons {
    display: flex;
    align-items: center;
    gap: 14px;
    img {
        width: 20px;
        height: 20px;
    }
}