

.field{
    background-color: rgba(71,73,88,.475);
    border-radius: 6px;
    padding: 10px;
    width: 100%;
    label{
        color: #fff;
        font-weight: bold;
    }
}