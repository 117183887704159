$color_1: rgba(0, 0, 0, 0.6);
$color_2: rgba(0, 0, 0, 0.87);
$font-family_1: 'Roboto', 'Helvetica', 'Arial', sans-serif;

.bg-img {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.instant-bg-img {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.fw-medium {
  font-weight: 500 !important;
}

.component.active {
  .--text,
  .--image {
    outline: solid 2px #33ada9 !important;
    outline-offset: 3px;
  }
}

.card-component.active {
  outline: solid 2px #33ada9 !important;
  outline-offset: 3px;
}

.text-h5 {
  margin: 0;
  font-family: $font-family_1;
  font-size: 1.5rem;
  line-height: 1.334;
  letter-spacing: 0em;
  text-align: center;
}

.text-h2 {
  margin: 0;
  font-family: $font-family_1;
  font-size: 3.75rem;
  line-height: 1.2;
  letter-spacing: -0.00833em;
  text-align: center;
  margin-bottom: 0.35em;
}

label {
  margin: 0 !important;
  font-weight: 600;
  font-size: 14px;
}

.clickable-img {
  cursor: pointer;
}
.bg-color {
  background-color: #7367f0 !important;
  color: white;
}
.white-stroke {
  stroke: white;
}
.f-b {
  font-weight: bold;
}
input {
  &::placeholder {
    font-size: 12px;
  }
}

.white-btn{
  background-color: white;
  color: #7367f0;
  border: 1px solid #7367f0;
}

.equipment-type-style{
   width: 55px;
   border-radius: 5px;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center; 
   min-height: 55px;
}

.truck-type-active {
  filter: brightness(0) invert(1);
} 

.truck-type {
  width: 65px;
  border-radius: 8px;
  display: flex;
  justify-items: center;
  align-items: center;
  flex-direction: column;
  padding: 6px;
  color: "#fff";
  margin: 2px;
  cursor: pointer;
  span {
    color: "#626262";
    margin-top: 2px;
    font-size: 10px;
    font-weight: 500;
    white-space: nowrap;
  }
}

.MuiFormLabel-asterisk{
  color: red;
}

.form-label{
 font-weight: bold!important;
 color: #000!important;
}

.MuiFormHelperText-contained{
  margin-left: 0!important;
}

.--section {
  &.highlight {
    position: relative;
    border: 2px dashed transparent;
    margin-bottom: 2rem;
    min-height: 200px;
    .toggle-section {
      position: absolute;
      display: none;
      top: 2rem;
      right: 2rem;
      z-index: 2;
      background-color: #7367f0;
      color: #fff;
      padding: 0.2rem 0.6rem;
      font-size: 0.8rem;
      border-radius: 0.4rem;
      cursor: pointer;
    }
    &:hover,
    &.active {
      border-color: #7367f0;
      .toggle-section {
        display: block;
      }
    }
    &.hidden {
      display: block;
      &::after {
        content: '';
        background-color: rgba(0, 0, 0, 0.4);
        position: absolute;
        inset: 0;
        z-index: 1;
        box-shadow: 0px 0px 12px -1px;
      }
    }
  }
  &.hidden {
    display: none;
  }
}