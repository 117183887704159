.wrapper-class {
  padding: 1rem;
  border: 1px solid #ccc;
  margin-bottom: 1rem;
}
.editor-container {
  max-width: 800px;
  margin: auto;
  padding: 2rem;
}

.editor-class {
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 60vh !important;
  overflow: auto;
}
.toolbar-class {
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
}
.preview {
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-top: 1rem;
  background-color: #f9f9f9;
}
img {
  max-width: 100%;
}
.preview-subject {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  padding: 0.5rem;
  border-bottom: 2px solid #ccc;
}

.blog-title {
  position: absolute;
  font-weight: 900 !important;
  color: #fff;
}

.truncate-lines {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 6;
}
.blog-wrapper {
  height: 52vh;
}
.rdw-image-modal-upload-option-label {
  display: block !important;
  text-overflow: ellipsis;
  overflow: hidden;
}
code {
  color: inherit;
}