
  .button {
    background: transparent;
    border: none;
    font-size: 0.9rem;
    font-weight: 400;
    padding: 9px 18px;
    border-radius: 6px;
    text-transform: uppercase;
  }
  .button-primary {
    background-color: #7367f0;
    color: #fff;
  }
  .button-outline {
    background-color: transparent;
    color: #fff;
    border: 1px solid #fff;
  }
