


.main-carousel{
    background: "#7367F0D9";
    height: 400px; 
    border-radius: 20px;
    position: relative;
    top: 100px;
}

