$color_1: #fff;
$color_2: rgba(255,255,255,.375);
$background-color_1: rgba(71,73,88,.475);
$background-color_2: rgba(71, 73, 88, .475);
$border-color_1: rgba(71,73,88,.475);

.round {
	position: relative;
	label {
		background-color: $background-color_1;
		border: 1px solid #ccc;
		border-radius: 50%;
		cursor: pointer;
		height: 28px;
		left: 0;
		position: absolute;
		top: 0;
		width: 28px;
		&:after {
			border: 2px solid #fff;
			border-top: none;
			border-right: none;
			content: "";
			height: 6px;
			left: 7px;
			opacity: 0;
			position: absolute;
			top: 8px;
			transform: rotate(-45deg);
			width: 12px;
		}
	}
	input[type="radio"] {
		visibility: hidden;
		&:checked+label {
			background-color: $background-color_1;
			border-color: $border-color_1;
			&:after {
				opacity: 1;
			}
		}
	}
}
.publish-field {
	resize: none;
	width: 100%;
	border-radius: 5px;
	border: none;
	color: $color_1;
	background-color: $background-color_2;
	margin: 5px 0;
	padding: 10px;
}
.radio-label {
	font-size: 1rem;
}
.note {
	font-size: 1em;
	color: $color_2;
}
