.company-card {
    max-width: 25rem;
    display: flex;
    background-color: #fff;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: 0px 4px 24px #22292F1A;
    border-radius: 10px;
    padding: 70px 30px;
}