.tracking-img {
    position: absolute;
    top: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    p {
      font-size: 60px;
      color: #fff;
      font-weight: bold;
    }
  }


  .tracking-hero-wrapper{
    height: 100vh;
    
  }


  .trucking-bg-image {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  }
  .tracking-card{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 65%;
  }