
.small-text{
    color: #6E6B7B;
    line-height: 1.2;
    font-weight: 300;
    margin-top: 20px;
    font-size: 1.1rem;
}
.small-text-color{
    color: #6E6B7B;
}

.wrapper-line-height{
    line-height: 1.5;
}

.steps-align{
    height: 300px;
    padding-top: 8rem;
}
.font-weight-bold{
   font-weight: bold;
}