$color_1: #fff;
$background-color_1: rgba(71, 73, 88, 0.475);
$error: red;

.editable-container {
  resize: none;
  width: 100%;
  border-radius: 5px;
  border: none;
  color: $color_1;
  background-color: $background-color_1;
  margin-top: 20px;
  padding: 10px;
  min-height: 100px;
}
.error {
  color: $error;
  font-weight: bold;
  margin-top: 5px;
}
.number-input {
  background-color: transparent !important;
  color: #fff !important;
  &:focus {
	border-color: #fff;
    box-shadow: none;
  }
}
