// .contact-hero-img {
//     width: 100%;
//     height: 60%;
//     background: url("../../../assets/images/contact-us/hero banner.png") no-repeat;
//     z-index: -1;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//   }

.contact-hero-img {
  position: absolute;
  top: 0;
  width: 100%;
  height: 60%;
  // background: url("../../../assets/images/contact-us/hero_banner.png") no-repeat;
  z-index: -1;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    font-size: 60px;
    color: #fff;
    font-weight: bold;
  }
}


.contact-bg-image {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}


.contact-hero-img p {
  font-size: 60px;
  color: #fff;
  font-weight: bold;
}

.scale {
  transform: scale(1.1)
}

.inquiry {
  margin-top: 100px;
}



.list-group li {
  list-style-type: none;
  line-height: 2.3;
}

.list-group.contact-address {
  font-weight: 500;

}

.icon {
  img {
    width: 20px;
    height: 20px;
  }
}


.company-card-wrapper {
  margin-top: -230px
}