.menu-elements-dropdown {
    background-color: #190F3A;

    li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 2px 15px;
        p{
            font-weight: 500;
        }
        .dropdown-item {
            &:hover {
                background: none;
            }
        }

    }
}