$color_1: #6E6B7B;
$color_2: #fff;
$color_3: #000;
$color_4: #6e6b7b;
$color_5: #919191;
$font-family_1: 'Frank Ruhl Libre', serif;
$background-color_1: #fff 33;
$background-color_2: #7367f0;
$background-color_3: #606060;
$background-color_4: #fff;
$background-color_5: #194693;
$border-color_1: #194693;

.color-small {
	color: $color_1;
}
.color-white{
	color: #fff
}

.hero-wrapper {
	height: 60vh;
}

.about-us {
	top: 0;
	z-index: -1;
	width: 100%;
	height: 100%;
}

.bg-about-img, .bg-blog-img {
	position: absolute;
	top: 0;
	width: 100%;
	height: 60%;
	z-index: -1;
	display: flex;
	justify-content: center;
	align-items: center;

	p {
		font-size: 60px;
		color: $color_2;
		font-weight: bold;
	}
}

.about-bg-img {
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
  }

.news-letter-input {
	background-color: $background-color_1;
	border: 0;
	border-radius: 5px;
	padding: 10px 20px;
	color: $color_2;
	width: 300px;
}

.about-card {
	background: #fff 0% 0% no-repeat padding-box;
	box-shadow: 0px 4px 24px #22292f 1a;
	border-radius: 10px;
	padding: 30px;
	opacity: 1;

	.card-head {
		color: $color_3;
		font-size: 20px;
		font-weight: bold;
	}

	.small-text {
		color: $color_4;
		font-size: 15px;
	}
}

.time-line {
	background-color: $background-color_2;
	padding: 124px 0;
	margin-top: 218px;
}

.timeline {
	width: 80%;
	margin: 0 auto;
	display: flex;
	justify-content: center;

	.events {
		position: relative;
		background-color: $background-color_3;
		height: 3px;
		width: 100%;
		border-radius: 4px;
		margin: 5em 0;

		ol {
			margin: 0;
			padding: 0;
			text-align: center;
		}

		ul {
			list-style: none;
			display: flex;
			justify-content: space-around;
			li {
				display: inline-block;
				margin: 0;
				padding: 0;

				p {
					font-size: 18px;
					color: $color_2;
					text-decoration: none;
					position: relative;
					top: -37px;

					&:after {
						content: '';
						position: absolute;
						bottom: -25px;
						left: 50%;
						right: auto;
						height: 20px;
						width: 20px;
						border-radius: 50%;
						border: 3px solid #606060;
						background-color: $background-color_4;
						transition: 0.3s ease;
						transform: translateX(-50%);
					}

				}

				a.selected {
					&:after {
						background-color: $background-color_5;
						border-color: $border-color_1;
					}
				}
			}
		}
	}
}

.events-content {
	width: 100%;
	height: 100px;
	max-width: 800px;
	margin: 0 auto;
	display: flex;
	justify-content: left;

	li {
		display: none;
		list-style: none;

		h2 {
			font-family: $font-family_1;
			font-weight: 500;
			color: $color_5;
			font-size: 2.5em;
		}
	}

	li.selected {
		display: initial;
	}
}

.case-carousel {
	height: 500px;
}
