.carousel-card{
    border-radius: 10px;
    box-shadow: 0px 4px 24px #22292F1A;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: none;
}

.carousel-card .card-body{
    padding: 20px;
}
.carousel-card .card-title{
    font-size: 17px;
}